
import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MUISelect from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';

import './Select.scss';

/**
 * Select.
 */
const propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType( [
      PropTypes.string,
      PropTypes.shape( {
        value: PropTypes.string,
        label: PropTypes.string,
      } )
    ] )
  ),
}
const Select = ( { items, label, ...p } ) => {

  return(

    <FormControl required>
      <InputLabel
      id={ p.labelId }
      >
        { label }
      </InputLabel>
      <MUISelect
      native
      { ...p }
      >
        { !! items && !! items.length && items.map( item => (
          <option value={ typeof item === 'string' ? item : item.value }>
            { typeof item === 'string' ? item : item.label }
          </option>
        ) ) }
      </MUISelect>
    </FormControl>

  );

};
Select.propTypes = propTypes;

export default Select;
