import classnames from 'classnames';
import React from 'react';
import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';

import BlockContent from 'molecules/BlockContent';
import Button from 'molecules/Button';
import Container from 'molecules/Container';
import Image from 'molecules/Image';
import Link from 'molecules/Link';
import Map from 'organisms/Map';
import Select from 'molecules/Select';
import SEO from 'molecules/SEO';
import TextField from 'molecules/TextField';

import { pageMetaFields } from 'lib/fragments';

import './index.scss';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

/**
 * Contact template.
 */
export const query = graphql`
  query ( $id: String ) {
    sanityPage( id: { eq: $id } ){
      id
      slug {
        current
      }
      title
      _rawPageTemplate(resolveReferences: { maxDepth: 10 })
      ...pageMetaFields
    }
  }
`;
const Contact = ( { data, path } ) => {

  const { watch, register, errors } = useForm();
  const [ investorType, setInvestorType ] = React.useState( '' );
  const [ isSubmitted, setIsSubmitted ] = React.useState( false );

  /**
   * Handle submit
   *
   * @param {object} e
   */
  const handleSubmit = e => {

    e.preventDefault();

    const form = e.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode( {
        'form-name': form.getAttribute('name'),
        name:     form.elements.name.value,
        email:    form.elements.email.value,
        company:  form.elements.company.value,
        ['investor-type']: form.elements['investor-type'].value,
        message:  form.elements.message.value,
      } ),
    } )
    .then( () => {
      setIsSubmitted( true );
    } )
    .catch( error => console.warn( error ) );

  }

  //const investorSelection = watch( 'investor-type' );


  const {
    title,
    meta,
    _rawPageTemplate: {
      Contact: {
        content,
        headline,
      }
    }
  } = data.sanityPage;


  return(

    <div className="Contact">

      <SEO
      title={ title }
      path={ path }
      { ...meta }
      />

      <section className="Contact__top">
        <Container>
          <div className="row">
            <div className="Contact__top-content col-md-7">
              <span className="d-block h-serif-i-sm">Contact Us</span>
              <h1 className="h-serif-lg">{ headline }</h1>
              <div className="content p-lg">
                <BlockContent blocks={ content } />
              </div>
            </div>
            <div className="Contact__top-side col-md-5">

              { ! isSubmitted

                ?
                  <form
                  className="form"
                  name="Contact"
                  method="post"
                  action="/contact?submit=true"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={ handleSubmit }
                  >
                    <TextField
                    inputRef={ register }
                    name="name"
                    label="Name"
                    required
                    />

                    <TextField
                    inputRef={ register }
                    id="email"
                    label="Email"
                    name="email"
                    htmlType="email"
                    required
                    />

                    <TextField
                    inputRef={ register }
                    name="company"
                    label="Company"
                    required
                    />
                    <Select
                    inputRef={ register }
                    name="investor-type"
                    labelId="investor-type-label"
                    value={ watch( 'investor-type' ) }
                    items={ [
                      '',
                      'Individual Investor',
                      'Financial Advisor',
                      'Institutional Investor',
                    ] }
                    label="Investor Type"
                    />
                    <TextField
                    inputRef={ register }
                    name="message"
                    label="Message"
                    multiline
                    rows={ 7 }
                    />
                    <Button
                    className="submit"
                    htmlType="submit"
                    color="teal"
                    >
                      Get in Touch
                    </Button>
                  </form>

                :
                  <div className="submitted h-sm">
                    Thank you for contact us. You will be hearing back from us shortly.
                  </div>
              }

            </div>
          </div>
        </Container>
      </section>

      <section className="Contact__map">
        <Map />
      </section>

    </div>

  )

};

export default Contact;
