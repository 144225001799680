import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import MaterialTextField from '@material-ui/core/TextField';

import './TextField.scss';

/**
 * Text field.
 */
const propTypes = {

}
const TextField = p => {

  const className = classnames(
    'TextField',
    p.className
  )

  return(

    <MaterialTextField { ...p } />

  )

};
TextField.propTypes = propTypes;

export default TextField;
